import {Game, Player, PlayingHistory, Wave} from "../types";


export function hasPlayedIn3vs3(player: Player, playingHistory: PlayingHistory): boolean {
    if (Object.keys(playingHistory).length === 0) return false;

    return playingHistory[player.name].previousGames.some(game => {
        return (game.team1.find(p => player.name === p.name) && game.team1.length === 3) ||
            !!(game.team2.find(p => player.name === p.name) && game.team2.length === 3);
    });
}

function buildHistory(game: Game, history: PlayingHistory): void {
    for (const teamName of ['team1', 'team2']) {
        const team = teamName === 'team1' ? game.team1 : game.team2;
        const opponentTeam = teamName === 'team1' ? game.team2 : game.team1;
        for (const player of team) {
            history[player.name] ??= {
                previousPartners: [],
                previousOpponents: [],
                gamesWon: 0,
                previousGames: []
            };

            history[player.name].gamesWon += game.winner === teamName ? 1 : 0;
            history[player.name].previousPartners.push(...team.filter(p => p.name !== player.name));
            history[player.name].previousOpponents.push(...opponentTeam);
            history[player.name].previousGames.push(game);
        }
    }

}

export function getPlayingHistory(previousWaves: Wave[]): PlayingHistory {
    const history: PlayingHistory = {};

    for (const games of previousWaves) {
        for (const game of games) {
            buildHistory(game, history);
        }
    }

    return history;
}
