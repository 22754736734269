import {Game, Group, PlayingHistory, Wave} from "../../types";
import {generate} from "../../business/wave-generator";
import {Alert, Button, Card, Tooltip} from "@mui/material";
import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {getPlayingHistory} from "../../business/playing-history";

export type WaveProps = { pointeurs: Group, tireurs: Group, goToRankPage: (waves: Wave[]) => void };

export default function WaveComponent(data: WaveProps) {
    const {goToRankPage, tireurs, pointeurs} = data;
    const [waves, setWaves] = useState<Wave[]>([generate(tireurs, pointeurs)]);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [currentWaveIndex, setCurrentWaveIndex] = useState<number>(0);

    const nextWave = () => {
        if (currentWaveIndex + 1 === waves.length) {
            setWaves([...waves, generate(tireurs, pointeurs, waves)]);
        }

        setCurrentWaveIndex(currentWaveIndex + 1);
    }

    const previousWave = () => {
        if (currentWaveIndex === 0) return;
        setCurrentWaveIndex(currentWaveIndex - 1);
    }

    const setWinner = (game: Game, winner: Game['winner']) => {
        game.winner = winner;
        setWaves([...waves]);
    };

    const isWinner = (game: Game, team: Game['winner']): boolean => {
        return game.winner === team;
    }

    const everyGamesHasWinner = (): boolean => {
        return waves.every((wave) => wave.every(game => !!game.winner));
    }

    const everyGamesHasWinnerForCurrentWave = (): boolean => {
        return waves[currentWaveIndex].every(game => !!game.winner);
    }

    const getCurrentPlayingHistory = (): PlayingHistory | null => {
        let previousIndex = currentWaveIndex - 1;
        if (previousIndex < 0) return null;
        return getPlayingHistory(waves.slice(0, previousIndex + 1));
    }

    const previousPartners = (playerName: string) => {
        const playingHistory = getCurrentPlayingHistory();
        if (!playingHistory) return <span>Le joueur n'a pas eu de partenaires à ce stade.</span>;

        return <span>Précédent partenaires de {playerName}
            <ul>{playingHistory[playerName].previousPartners.map(p => <li>{p.name}</li>)}</ul></span>;
    }

    return (
        <div className="Wave">
            <h1>PARTIE {currentWaveIndex + 1}</h1>
            <Alert severity="info">Cliquer sur une équipe pour la définir gagnante.</Alert>

            <section className="Wave--games">
                {waves[currentWaveIndex].map((game, index) => {
                    return (
                        <div className="Wave--games--game" key={index}>
                            <Card
                                className={isWinner(game, 'team1') ? 'Wave--games--game--team Wave--games--game--team_winner' : 'Wave--games--game--team'}
                                onClick={() => setWinner(game, 'team1')}>
                                {game.team1.map((player) => {
                                    return <p key={player.name}
                                              className='Wave--games--game--team--name'>
                                        <Tooltip
                                            title={previousPartners(player.name)}>
                                            <span>{player.name}</span>
                                        </Tooltip>
                                    </p>
                                })}
                            </Card>

                            <span className="Wave--games--game--VS">VS</span>

                            <Card
                                className={isWinner(game, 'team2') ? 'Wave--games--game--team Wave--games--game--team_winner' : 'Wave--games--game--team'}
                                onClick={() => setWinner(game, 'team2')}>
                                {game.team2.map((player) => {
                                    return <p key={player.name}
                                              className='Wave--games--game--team--name'>
                                        <Tooltip
                                            title={previousPartners(player.name)}>
                                            <span>{player.name}</span>
                                        </Tooltip>
                                    </p>
                                })}
                            </Card>
                        </div>
                    );
                })}
            </section>

            <section className="Wave--buttons">
                <Button variant="contained" color="info"
                        className="Wave--buttons--button"
                        disabled={!currentWaveIndex}
                        onClick={previousWave}
                >
                    Partie précédente
                </Button>
                <Button variant="contained" color="warning"
                        className="Wave--buttons--button"
                        disabled={!everyGamesHasWinner()}
                        onClick={() => setIsConfirmDialogOpen(true)}
                >
                    Terminer pour voir le classement
                </Button>

                <Button variant="contained" color="secondary" className="Wave--buttons--button"
                        disabled={!everyGamesHasWinnerForCurrentWave()}
                        onClick={nextWave}
                >
                    Partie suivante
                </Button>
            </section>

            <Dialog onClose={() => setIsConfirmDialogOpen(false)} open={isConfirmDialogOpen}
                    className='Wave--confirm-end'>
                <DialogTitle>Fin du tournoi</DialogTitle>
                <section className="Wave--confirm-end--text">
                    <p>Vous êtes sur le point de mettre fin au tournoi. Si vous continuez, vous ne pourrez plus revenir
                        en arrière et allez voir le classement.</p>
                    <p>Êtes-vous sûr ?</p>
                </section>

                <section className="Wave--confirm-end--buttons">
                    <Button variant="contained" color="secondary" className="Wave--confirm-end--buttons--button"
                            onClick={() => setIsConfirmDialogOpen(false)}>
                        Annuler
                    </Button>

                    <Button variant="contained" color="warning" className="Wave--confirm-end--buttons--button"
                            onClick={() => goToRankPage(waves)}>
                        Voir le classement
                    </Button>
                </section>
            </Dialog>
        </div>
    );
}
