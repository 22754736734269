import React from "react";
import Players from "../../components/players";
import { Group, Wave } from "../../types";
import { Alert, Button } from "@mui/material";
import WaveComponent from "../../components/wave";
import { generate } from "../../business/wave-generator";
import Rank from "../../components/rank";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "../../components/players/style.scss";
import "./style.scss";
import "../../style.scss";
import "../../components/rank/style.scss";
import "../../components/wave/style.scss";

export default function Home() {
  const [pointeurs, setPointeurs] = React.useState<Group>([]);
  const [tireurs, setTireurs] = React.useState<Group>([]);
  const [isGameStarted, setIsGameStarted] = React.useState<boolean>(false);
  const [waves, setWaves] = React.useState<Wave[]>([]);

  const onPointeursChange = (players: Group) => {
    setPointeurs(players);
  };

  const onTireursChange = (players: Group) => {
    setTireurs(players);
  };

  const startTheGame = () => {
    setIsGameStarted(true);
  };

  const canStartTheGame = (): boolean => {
    try {
      return !!generate(tireurs, pointeurs);
    } catch (e) {
      return false;
    }
  };

  const totalPlayers = (): number => {
    return pointeurs.length + tireurs.length;
  };

  if (waves.length > 0) {
    return <Rank waves={waves} />;
  } else if (isGameStarted) {
    return (
      <WaveComponent
        tireurs={tireurs}
        pointeurs={pointeurs}
        goToRankPage={setWaves}
      />
    );
  } else {
    return (
      <div className="App">
        <h2>
          Essayer la version 2 de l'application, elle propose plus de
          fonctionnalités
        </h2>
        <h2>
          <a href="https://v2.petanquemaster.com/">
            https://v2.petanquemaster.com/
          </a>
        </h2>
        <section className="App--contact">
          <p>Besoin d'aide ou de nous faire part de vos commentaires ?</p>
          <b>
            Ou pour partager vos expériences et aider à créer des solutions
            adaptées à vos besoins !
          </b>
          <p>
            Contactez-nous à{" "}
            <a href="mailto:mywallets.app@gmail.com">mywallets.app@gmail.com</a>{" "}
            !
          </p>
        </section>
        {!canStartTheGame() && (
          <Alert severity="warning">
            Impossible de commencer la partie avec {totalPlayers()}{" "}
            {totalPlayers() === 0 ? "joueur" : "joueurs"}.{" "}
          </Alert>
        )}
        <section className="App--all">
          <section className="App--all--players">
            <h1>Tireurs ({tireurs.length})</h1>
            <Players
              onPlayersChange={onTireursChange}
              otherPlayers={pointeurs}
            />
          </section>

          <section className="App--all--players">
            <h1>Pointeurs ({pointeurs.length})</h1>
            <Players
              onPlayersChange={onPointeursChange}
              otherPlayers={tireurs}
            />
          </section>
        </section>
        <section className="App--startGame">
          <Button
            variant="contained"
            color="secondary"
            className="App--startGame--button"
            disabled={!canStartTheGame()}
            onClick={startTheGame}
          >
            Commencer le tournoi
          </Button>
        </section>
      </div>
    );
  }
}
