import {Wave} from "../../types";
import {getPlayingHistory} from "../../business/playing-history";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button} from "@mui/material";
import React from "react";
import {CSVLink} from "react-csv";

export type RankProps = { waves: Wave[] };

export default function Rank(data: RankProps) {
    const playingHistory = getPlayingHistory(data.waves);
    const rows = Object.entries(playingHistory).map(([name, history]) => ({name, gameWon: history.gamesWon}));
    rows.sort((a, b) => b.gameWon - a.gameWon);

    const headers = [{label: "Nom", key: "name"}, {label: "Victoires", key: "gameWon"}];

    return (
        <div className='Rank'>
            <h1>Classement des {data.waves.length} partie(s)</h1>
            <CSVLink filename={"classement.csv"}
                     data={rows} headers={headers}>Télécharger le classement</CSVLink>
            <TableContainer component={Paper} className='Rank--table'>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell align="right">Victoires</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.gameWon}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <section className="Rank--endGame">
                <Button variant="contained" color="secondary" className="Rank--endGame--button"
                        onClick={() => window.location.reload()}
                >
                    Revenir à l'accueil
                </Button>
            </section>
        </div>
    );
}
