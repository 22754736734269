import {Player} from "../types";

export const validatePlayers = (playersA: Player[], playersB: Player[]): void => {
    const mergedTeam = [...playersA, ...playersB];

    if (new Set(mergedTeam.map(player => player.name)).size !== mergedTeam.length) {
        throw new Error('Players must be unique');
    }
    if (playersA.length + playersB.length < 4) throw new Error('Not enough players');
}
