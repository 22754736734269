import {Group, PlayingHistory, Team, Wave} from "../types";
import {getPlayingHistory, hasPlayedIn3vs3} from "./playing-history";
import {validatePlayers} from "./validator";

function buildGames(teams: Team[], history: PlayingHistory, nextWave: Wave): Team[] {
    while (teams.length >= 2) {
        const teamIndex = Math.floor(Math.random() * teams.length);
        const team1 = teams[teamIndex];
        // remove team1 from the list of teams
        teams = teams.filter(team => team !== team1);
        const previousOpponents = team1.map(player => history[player.name]?.previousOpponents ?? []).flat();
        // get potentials teams that do not have any player that played against team1
        let potentialOpponents = teams.filter(team => !team.some(player => previousOpponents.includes(player)));
        // if no potential opponents, we bypass the previousOpponents constraint
        if (potentialOpponents.length === 0) potentialOpponents = teams;
        const team2Index = Math.floor(Math.random() * potentialOpponents.length);
        const team2 = potentialOpponents[team2Index];
        // remove team2 from the list of teams
        teams = teams.filter(team => team !== team2);
        // save the newt wave
        nextWave.push({team1, team2});
    }

    return teams;
}

export const generate = (playersA: Group, playersB: Group, previousWaves: Wave[] = [], stackCall: number = 0): Wave => {
    validatePlayers(playersA, playersB);

    const nextWave: Wave = [];
    const mixedPlayersA = [...playersA].sort(() => Math.random() - 0.5);
    const mixedPlayersB = [...playersB].sort(() => Math.random() - 0.5);
    const history = getPlayingHistory(previousWaves);
    let teams: Team[] = [];

    const playersWithLessPartners = mixedPlayersA.length < mixedPlayersB.length ? mixedPlayersA : mixedPlayersB;
    const playersWithMorePartners = mixedPlayersA.length < mixedPlayersB.length ? mixedPlayersB : mixedPlayersA;

    if (playersWithMorePartners.length - playersWithLessPartners.length > 1) {
        const countOfPlayerToCompleteTeams = Math.ceil((playersWithMorePartners.length - playersWithLessPartners.length) / 2);
        const playersToCompleteTeams = playersWithMorePartners.splice(0, countOfPlayerToCompleteTeams);
        playersWithLessPartners.push(...playersToCompleteTeams);
    }

    // generate teams randomly
    while (mixedPlayersA.length > 0 && mixedPlayersB.length > 0) {
        const playerA = mixedPlayersA[0];
        const previousPartners = history?.[playerA.name]?.previousPartners ?? [];
        let potentialPartners = mixedPlayersB.filter(p => !previousPartners.includes(p));
        // if no potential partners, we bypass the previousPartners constraint
        if (potentialPartners.length === 0) potentialPartners = mixedPlayersB;

        let partnerB = potentialPartners[0];
        const partners = potentialPartners.filter(p => hasPlayedIn3vs3(playerA, history) === hasPlayedIn3vs3(p, history));
        if (partners.length > 0) partnerB = partners[0];
        teams.push([playerA, partnerB]);

        // remove partnerB from the list of players
        mixedPlayersB.splice(mixedPlayersB.indexOf(partnerB), 1);
        // remove playerA from the list of players
        mixedPlayersA.splice(mixedPlayersA.indexOf(playerA), 1);
    }

    // TODO: refactor this part because there is probably a better way to do it
    // if the remaining players are already played in 3 vs 3, we try to swap players that have never played in 3 vs 3
    if (mixedPlayersA.length === 1) {
        // find a player that has never played in 3 vs 3
        const teamWithPlayerToSwap = teams.find(team => !hasPlayedIn3vs3(team[0], history));
        if (teamWithPlayerToSwap) {
            // replace the player in the team
            const playerToSwap = teamWithPlayerToSwap[0];
            teamWithPlayerToSwap[0] = mixedPlayersA[0];
            mixedPlayersA[0] = playerToSwap;
        }
    } else if (mixedPlayersB.length === 1) {
        // find a player that has never played in 3 vs 3
        const teamWithPlayerToSwap = teams.find(team => !hasPlayedIn3vs3(team[1], history));
        if (teamWithPlayerToSwap) {
            // replace the player in the team
            const playerToSwap = teamWithPlayerToSwap[1];
            teamWithPlayerToSwap[1] = mixedPlayersB[0];
            mixedPlayersB[0] = playerToSwap;
        }
    }

    // complete teams with remaining players
    const remainingPlayers = [...mixedPlayersA, ...mixedPlayersB];

    // if there is an odd number of teams, we must remove a team to have an even number of teams
    if (teams.length % 2 === 1) {
        // find a team who has never played in the 3 vs 3
        const indexTeamToRemove = teams.findIndex(team => {
            return team.find(player => !hasPlayedIn3vs3(player, history));
        });
        // remove the team from the list of teams
        // add the players of the team to the list of remaining players
        remainingPlayers.push(...teams.splice(indexTeamToRemove, 1)[0]);
    }

    // complete teams with remaining players
    remainingPlayers.forEach(player => {
        const previousPartners = history[player.name]?.previousPartners ?? [];
        const previousOpponents = history[player.name]?.previousOpponents ?? [];
        let potentialTeams = teams.filter(team =>
            !team.some(player => previousPartners.includes(player)
                || previousOpponents.includes(player)
                || team.length === 3));
        // complete teams that do not have at least 2 players
        const potentialTeamsWithOnePLayer = potentialTeams.filter(team => team.length === 1);
        if (potentialTeamsWithOnePLayer.length > 0) {
            const partners = potentialTeamsWithOnePLayer.filter(p => {
                return hasPlayedIn3vs3(player, history) === hasPlayedIn3vs3(p[0], history);
            });
            if (partners.length > 0) partners[0].push(player);
            else potentialTeamsWithOnePLayer[0].push(player);
        } else {
            // when there is not potential teams, we bypass the previousPartners and previousOpponents constraints
            potentialTeams = teams.filter(team => team.length === 1);
            if (potentialTeams.length > 0) potentialTeams[0].push(player);
            else {
                potentialTeams = teams.filter(team => team.length === 2);
                if (potentialTeams.length === 0) throw new Error('Impossible to generate teams');

                // in this case we have only teams with 2 players
                const teamWithPlayersThatNeverPlayedIn3vs3 = potentialTeams.find(team => {
                    return team.every(player => !hasPlayedIn3vs3(player, history));
                });
                if (teamWithPlayersThatNeverPlayedIn3vs3) teamWithPlayersThatNeverPlayedIn3vs3.push(player);
                else potentialTeams[0].push(player);
            }
        }
    });

    // generate opponents randomly for team that have less than 3 players
    const teamsWithTwoPlayers = teams.filter(team => team.length === 2);
    const remainingTeamsWithTwoPlayers = buildGames(teamsWithTwoPlayers, history, nextWave);

    // generate opponents randomly for team that have 3 players and the remaining teams with 2 players
    const teamsWithThreePlayers = teams.filter(team => team.length === 3);
    buildGames([...teamsWithThreePlayers, ...remainingTeamsWithTwoPlayers], history, nextWave);

    // TO REFACTOR IS THE NEW APP
    const historyToCheck = getPlayingHistory([...previousWaves, nextWave]);
    let previousPartners = 0;
    let previousOpponents = 0;

    Object.entries(historyToCheck).forEach(([playerName, playerHistory]) => {
        // never same partner
        if (playerHistory.previousPartners.length !== new Set(playerHistory.previousPartners.map(p => p.name)).size) {
            previousPartners++;
        }
        if (playerHistory.previousOpponents.length !== new Set(playerHistory.previousOpponents.map(p => p.name)).size) {
            previousOpponents++;
        }
    });

    if (stackCall < 1000 && (previousPartners > 0 || previousOpponents > 0)) {
        return generate(playersA, playersB, previousWaves, stackCall + 1);
    }


    return nextWave;
};
